import lozad from "lozad";
import Vue from "vue/dist/vue.esm";

require("../../css/tracker/app_front.scss");
require("../../css/tracker/common.scss");
require("../../css/tracker/front/common.scss");
require("../../css/tracker/front/menu.scss");
require("../../css/tracker/baloo.css");

global.$ = global.jQuery = require("jquery");
require("jquery-ui-bundle");
require("bootstrap");

window.Vue = Vue;

global.AOS = require("aos");
AOS.init({
  offset: 60,
});

window.lozad = lozad;

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();
